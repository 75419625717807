import * as React from "react"
import Layout from '../components/Layout'
import SEO from "../components/seo"
import {Zul} from '../components/Zul';

const ZulPage = () => {
  return (
    <Layout>
      <SEO
        title="The Ocean Group"
        description="Ofrecemos oportunidades inmobiliarias en la costa de Oaxaca"
        works=''
      />

      <Zul/>

    </Layout>
  )
}

export default ZulPage
