import styled, {createGlobalStyle} from 'styled-components';
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from "../../styles/GlobalStyles";

export const GlobalStyleOxean = createGlobalStyle`
  .oxean{
    background-color: ${GlobalBackgroundColors.bgPrimary};
  }
  .cont-fig-der{
    display: flex;
    align-items: flex-end;
  }
`
export const ContTextoInfo = styled.div`
  width: 100%;
  padding: 25px;
  font: 24px ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorPrimary};
  text-align: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 20px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 18px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 20px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 22px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 24px;
  }
  @media(min-width: 1920px){
    font-size: 24px;
  }
`
export const ContTextoProximamente = styled.div`
  width: 100%;
  font: 72px ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorPrimary};
  text-align: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 48px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 54px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 68px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 68px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 72px;
  }
  @media(min-width: 1920px){
    font-size: 72px;
  }
`

