import React from 'react';
import {Container, Columns, Column} from 'bloomer';
import {ContTextoInfo, ContTextoProximamente, GlobalStyleOxean} from './styles';

export const Zul = (props) => {

  return (
    <Container className={'back-oxean'} isFluid={true}>
      <GlobalStyleOxean/>
      <Columns className={'columns-margin-side-no full-height display-flex'} isMultiline={true}>
        <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
          <ContTextoInfo>
            El status de este desarrollo es próximamente
          </ContTextoInfo>
        </Column>
        <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
          <ContTextoProximamente>
            Próximamente más información
          </ContTextoProximamente>
        </Column>
      </Columns>
    </Container>
  );
};
